<template>
  <div>
    <van-cell title="跟进状态" :value="value" @click="showPicker = true" />
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      value: "线索",
      showPicker: false,
      columns: ["线索", "潜在", "意向", "成单", "复购", "丢单", "无效"],
    };
  },
  methods: {
    onConfirm(value) {
      this.value = value;
      this.showPicker = false;
      this.emit("value", value);
    },
  },
};
</script>
