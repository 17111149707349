<template>
  <div>
    <van-cell title="选择完成时间" :value="dateValue" @click="show = true" />
    <van-calendar
      v-model="show"
      @confirm="onConfirm"
      color="#1989fa"
      @select="dateSelect"
    />
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
        title="时间选择"
        :columns="columns"
        @cancel="showPicker = false"
        @change="onPickerChange"
        swipe-duration="500"
        visible-item-count="5"
      >
        <!-- <div class="picker-title">{{ pickerText }}</div> -->
      </van-picker>
    </van-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
      dateValue: this.dayjs().format("YYYY-MM-DD hh:mm:ss"),
      showPicker: false,
      timeValue: "",
      columns: [
        {
          values: [
            "00",
            "01",
            "02",
            "03",
            "04",
            "05",
            "06",
            "07",
            "08",
            "09",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
          ],
          defaultIndex: 0,
        },
        {
          values: [
            "00",
            "01",
            "02",
            "03",
            "04",
            "05",
            "06",
            "07",
            "08",
            "09",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
            "35",
            "36",
            "37",
            "38",
            "39",
            "40",
            "41",
            "42",
            "43",
            "44",
            "45",
            "46",
            "47",
            "48",
            "49",
            "50",
            "51",
            "52",
            "53",
            "54",
            "55",
            "56",
            "57",
            "58",
            "59",
          ],
          defaultIndex: 0,
        },
        {
          values: [
            "00",
            "01",
            "02",
            "03",
            "04",
            "05",
            "06",
            "07",
            "08",
            "09",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
            "35",
            "36",
            "37",
            "38",
            "39",
            "40",
            "41",
            "42",
            "43",
            "44",
            "45",
            "46",
            "47",
            "48",
            "49",
            "50",
            "51",
            "52",
            "53",
            "54",
            "55",
            "56",
            "57",
            "58",
            "59",
          ],
          defaultIndex: 0,
        },
      ],
    };
  },
  methods: {
    onConfirm(date) {
      this.show = false;
      this.dateValue = this.dayjs(date).format("YYYY-MM-DD");
      this.dateValue += " " + this.timeValue;
      this.$emit("finishTime", this.dateValue);
    },
    dateSelect() {
      this.showPicker = true;
    },
    onPickerChange(el, value) {
      console.log(el);
      this.timeValue = value.join(":");
    },
  },
};
</script>
