<template>
  <div>
    <van-cell title="下次跟进提醒" />
    <van-calendar
      v-model="showNext"
      @confirm="onConfirmNext"
      color="#1989fa"
      @select="dateSelectNext"
    />
    <van-cell
      title="日程时间"
      :value="dateValueNext"
      @click="showNext = true"
    />
    <van-popup v-model="showPickerNext" round position="bottom">
      <van-picker
        title="时间选择"
        :columns="columnsNext"
        @cancel="showPickerNext = false"
        @change="onPickerChangeNext"
        swipe-duration="500"
        visible-item-count="5"
      >
        <!-- <div class="picker-title">{{ pickerText }}</div> -->
      </van-picker>
    </van-popup>
    <van-cell title="提醒时间" :value="value" @click="showPicker = true" />
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      columns: [
        { text: "准时提醒", value: 0 },
        { text: "提前15分钟", value: 900000 },
        { text: "提前30分钟", value: 1800000 },
        { text: "提前1小时", value: 3600000 },
        { text: "提前1天", value: 86400000 },
      ],
      value: "",
      showPicker: false,
      showNext: false,
      dateValueNext: this.dayjs().format("YYYY-MM-DD hh:mm:ss"),
      showPickerNext: false,
      timeValueNext: "",
      columnsNext: [
        {
          values: [
            "00",
            "01",
            "02",
            "03",
            "04",
            "05",
            "06",
            "07",
            "08",
            "09",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
          ],
          defaultIndex: 0,
        },
        {
          values: [
            "00",
            "01",
            "02",
            "03",
            "04",
            "05",
            "06",
            "07",
            "08",
            "09",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
            "35",
            "36",
            "37",
            "38",
            "39",
            "40",
            "41",
            "42",
            "43",
            "44",
            "45",
            "46",
            "47",
            "48",
            "49",
            "50",
            "51",
            "52",
            "53",
            "54",
            "55",
            "56",
            "57",
            "58",
            "59",
          ],
          defaultIndex: 0,
        },
        {
          values: [
            "00",
            "01",
            "02",
            "03",
            "04",
            "05",
            "06",
            "07",
            "08",
            "09",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
            "35",
            "36",
            "37",
            "38",
            "39",
            "40",
            "41",
            "42",
            "43",
            "44",
            "45",
            "46",
            "47",
            "48",
            "49",
            "50",
            "51",
            "52",
            "53",
            "54",
            "55",
            "56",
            "57",
            "58",
            "59",
          ],
          defaultIndex: 0,
        },
      ],
    };
  },
  methods: {
    onConfirmNext(date) {
      this.showNext = false;
      this.dateValueNext = this.dayjs(date).format("YYYY-MM-DD");
      this.dateValueNext += " " + this.timeValueNext;
      this.$emit("nextDate", this.dateValueNext);
    },
    dateSelectNext() {
      this.showPickerNext = true;
    },
    onPickerChangeNext(el, value) {
      console.log(el);
      this.timeValueNext = value.join(":");
    },
    onConfirm(value) {
      this.value = value.text;
      this.showPicker = false;
      this.$emit("limit", value.value);
    },
  },
};
</script>

