<template>
  <div>
    <van-cell v-for="(item, index) in data" :key="index">
      <template #title>
        <span> {{ item.createTime }} {{ item.content }}</span>
      </template>
    </van-cell>
  </div>
</template>
<script>
export default {
  props: { userId: String },
  data() {
    return {
      data: [],
    };
  },
  methods: {
    //倒序
    sortKey(array, key) {
      return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        return x > y ? -1 : x < y ? 1 : 0;
      });
    },
  },
  created() {},
  mounted() {
    if (this.userId) {
      let result = [];
      this.$axios
        .get(`${this.$base}/management/user/${this.userId}`)
        .then((res) => {
          if (res.code === 200) {
            res.data.userVisitLog = this.sortKey(
              res.data.userVisitLog,
              "createTime"
            );
            res.data.userVisitLog.forEach((item, index) => {
              if (index > 4) {
                return;
              }
              console.log(index);
              if (item.createTime) {
                item.createTime = this.dayjs(item.createTime).format(
                  "YYYY-MM-DD hh:mm:ss"
                );
              }
              result.push(item);
            });
            this.data = result;
          }
        });
    }
  },
};
</script>
