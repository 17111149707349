<template>
  <div>
    <van-nav-bar title="反馈记录" />
    <van-field
      v-model="account"
      label="账号"
      type="text"
      placeholder="账号"
      readOnly
    />
    <van-field
      v-model="name"
      label="联系人"
      type="text"
      placeholder="联系人"
      readOnly
    />
    <van-field
      v-model="logValue"
      label="本次跟进内容"
      placeholder="请输入本次跟进内容"
      :border="true"
      autosize
      type="textarea"
    />
    <timepick @finishTime="getFinishTime" />
    <statusChoose @value="getStatus" />
    <van-cell title="更近提醒">
      <!-- 使用 title 插槽来自定义标题 -->
      <template #default>
        <van-switch v-model="checked" size="24" />
      </template>
    </van-cell>
    <nextVisit v-if="checked" @nextDate="getNextDate" @limit="getLimit" />
    <van-button type="info" size="large" :square="true" @click="apply"
      >保存</van-button
    >
    <logHis :userId="userId" />
  </div>
</template>
<script>
import timepick from "./compents/timepick.vue";
import statusChoose from "./compents/statusChoose.vue";
import nextVisit from "./compents/nextVisit.vue";
import logHis from "./compents/logHis.vue";
export default {
  components: { timepick, statusChoose, nextVisit, logHis },
  data() {
    return {
      logValue: "", //反馈记录
      name: "",
      checked: false,
      nextDate: "",
      limit: 0,
      finishTime: this.dayjs().format("YYYY-MM-DD hh:mm:ss"),
      visitStatus: "线索",
      userId: "",
      account: "",
    };
  },
  methods: {
    //获取下次更近时间
    getNextDate(val) {
      this.nextDate = val;
    },
    //获取时间偏移
    getLimit(val) {
      this.limit = val;
    },
    //获取完成时间
    getFinishTime(val) {
      this.finishTime = val;
    },
    //获取跟进状态
    getStatus(val) {
      this.visitStatus = val;
    },
    apply() {
      if (!this.finishTime) {
        this.$toast("请选择跟进完成时间");
        return;
      }
      if (this.nextDate) {
        this.$axios
          .post(`${this.$base}/fission/wechat/visitLogTask`, {
            remindTime: this.nextDate,
            limit: this.limit,
            finishTime: this.finishTime,
            visitStatus: this.visitStatus,
            logValue: this.logValue,
            name: this.name,
            userId: this.userId,
            account: this.account,
          })
          .then((res) => {
            if (res.code === "200") {
              this.$toast("操作成功");
            } else {
              this.$toast("操作失败!!!!");
            }
          });
      }

      this.$axios
        .post(`${this.$base}/management/user/${this.userId}/visit-log`, {
          content: this.logValue,
          time: this.dayjs(this.finishTime).valueOf(),
          userId: this.userId,
        })
        .then((res) => {
          if (res.id) {
            this.$toast("操作成功");
          } else {
            this.$toast("操作失败!!!!");
          }
        });
    },
  },
  created() {
    this.userId = this.$route.query.id;
  },
  mounted() {
    this.$axios
      .get(`${this.$base}/management/user/${this.userId}`)
      .then((res) => {
        if (res.code === 200) {
          this.account = res.data.account;
          this.name = res.data.nickName;
        }
      });
  },
};
</script>
